import React from 'react';
import { Box, Button, Fade, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';

function SignInForm() {

    const { handleSignIn, signingIn, errorValue } = useAuthContext();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    return (
        <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
            <TextField
                id="username"
                name="username"
                label="Username"
                type="text"
                fullWidth
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
                autoComplete="username"
                sx={{ mt: 1, mb: 1 }}
            />
            <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                autoComplete="current-password"
                sx={{ mt: 1, mb: 1 }}
                // allow the user to see the password with the eye icon
                InputProps={{
                    endAdornment: (
                        <Button
                            aria-label="toggle password visibility"
                            onClick={() => {
                                const passwordField = document.getElementById("password");
                                if (passwordField) {
                                    const passwordFieldType = passwordField.getAttribute("type");
                                    if (passwordFieldType === "password") {
                                        passwordField.setAttribute("type", "text");
                                    } else {
                                        passwordField.setAttribute("type", "password");
                                    }
                                }
                            }}
                        >
                            {password ? "👁️‍🗨️" : ""}
                        </Button>
                    ),
                }}
            />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ height: "30px", mt: 0, color: "red" }}>
                {errorValue}
            </Typography>
            <Stack sx={{ width: '100%' }} spacing={-0.1}>
                <Button
                    type="submit"
                    fullWidth
                    // disabled={signInAllowed}
                    variant="contained"
                    sx={{ mt: 1, mb: 1, height: '45px', borderRadius: 0 }}
                >
                    Sign In
                </Button>
                <Fade in={signingIn} unmountOnExit>
                    <LinearProgress color="primary" sx={{ display: "block", height: "5px" }} />
                </Fade>
            </Stack>
        </Box>
    );
}

export default SignInForm;