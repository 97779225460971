import { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useAuthContext } from '../context/AuthContext';
import Loading from './Loading';
import PdfIcon from '@mui/icons-material/Description';
export default function MinimalDocumentsTab() {

    const { idToken } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState<{ name: string; downloadUrl: string; }[]>([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", idToken);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow" as RequestRedirect
            };

            try {
                const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/documents`;
                const response = await fetch(url, requestOptions);
                const result = await response.json();

                const fetchedDocuments = result.filenames.map((filename: string) => {
                    const name = filename.replace('documents/', '').replace('.pdf', '');
                    return { name, downloadUrl: filename };
                });

                setDocuments(fetchedDocuments);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching documents:", error);
                setLoading(false); // Still stop loading even on error
            }
        };

        fetchDocuments();
    }, [idToken]);

    const handleDownload = async (name: string) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", idToken);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "filename": `${name}.pdf`
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow" as RequestRedirect
        };

        try {
            const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/documents`;
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            window.open(result.url, '_blank');
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        loading ? <Loading /> :
            <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
                <Typography variant="h1">Documents</Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    For each content group in the curriculum, please find here a corresponding document to be distributed to the students.
                </Typography>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                    {documents.map((document, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <Card sx={{ position: 'relative', overflow: 'hidden' }}>
                                <CardMedia
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 0,
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        fontSize: '7rem',
                                        opacity: 0.1,
                                        color: 'rgba(a, a, a, 0.1)'
                                    }}
                                >
                                    <PdfIcon fontSize="inherit" />
                                </CardMedia>
                                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                    <Typography variant="h5" component="div">
                                        {document.name}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleDownload(document.name)}
                                        sx={{ mt: 2 }}
                                        startIcon={<DownloadIcon />}
                                    >
                                        Download
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
    );
}