import { createTheme } from '@mui/material/styles';
import { pink, purple } from '@mui/material/colors';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: pink,
        secondary: purple
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 800,

        },
        h2: {
            fontSize: '2rem',
            fontWeight: 800
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 800
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 800
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 800
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 800
        },


    },
    shape: {
        borderRadius: 3
    },



});

export { darkTheme };