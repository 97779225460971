import React from 'react';
import { Box, Avatar, Typography, Menu, Button } from '@mui/material';

interface UserMenuProps {
    username: string;
    anchorEl: HTMLElement | null;
    handleMenu: (event: React.MouseEvent<HTMLElement>) => void;
    handleClose: () => void;
    handleSignOut: (event: React.FormEvent<HTMLFormElement>) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ username, anchorEl, handleMenu, handleClose, handleSignOut }) => {
    return (
        <>
            <Button color="inherit" onClick={handleMenu} sx={{ borderRadius: 0, textTransform: "none" }} >
                <Box display="flex" alignItems="center">
                    <Avatar alt={username} src="/static/images/avatar/1.jpg" />
                    <Typography variant="body1" color="inherit" style={{ marginLeft: 8 }}>
                        {username}
                    </Typography>
                </Box>
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Typography variant="body1" align="center" sx={{ p: 1 }}>
                    {"You are logged in as " + username}
                </Typography>
                <Box component="form" width={400} onSubmit={handleSignOut} noValidate sx={{ p: 0 }}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 1, height: '45px', borderRadius: 0 }}>
                        Sign Out
                    </Button>
                </Box>
            </Menu>
        </>
    );
};

export default UserMenu;