import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemIcon, Link } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface FAQItem {
    question: string;
    answer: React.ReactNode;
}

interface FrequentlyAskedQuestionProps {
    frequentlyAskedQuestions: FAQItem[];
}

const FrequentlyAskedQuestion: React.FC<FrequentlyAskedQuestionProps> = ({ frequentlyAskedQuestions }) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {frequentlyAskedQuestions.map((faq, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <Link href="https://www.meta.com/help/quest/articles/getting-started/getting-started-with-quest-2/connect-quest-to-wi-fi/">
                                <HelpOutlineIcon />
                            </Link>
                        </ListItemIcon>
                        <Typography sx={{ wordWrap: 'break-word' }} variant="body2">
                            {faq.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {faq.answer}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default FrequentlyAskedQuestion;