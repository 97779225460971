import React, { useState, useEffect } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Button,
    Typography,
    TableContainer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Fade,
    LinearProgress,
    Stack,
    Box,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthContext } from '../context/AuthContext';

import { Track, ReturnData, Artist, Content } from '../models/TrackInstallVisibility';

const deepCopy = <T,>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj));
    // return JSON.parse(JSON.stringify(obj)).content;
};

const initializeData = (data: Content): Content => {
    const newData = deepCopy(data);
    // console.log('New data:', newData);
    newData.artists.forEach((artist) => {
        if (Array.isArray(artist.tracks)) {
            artist.tracks.forEach((track) => {
                if (track.visibility === undefined) track.visibility = false; // default visibility to false
                if (track.install === undefined) track.install = false; // default install to false
            });
        } else {
            artist.tracks = []; // Initialize tracks as an empty array if it's undefined
        }
    });
    return newData;
};

const TrackTable: React.FC = () => {

    // State management for track data:
    // - soapboxData: Raw data from the API
    // - data: Processed and initialized data for rendering and local changes
    // - originalData: A copy of the initial data for comparison
    // - newData: Temporary copy used for updates before setting to 'data'
    //
    // This structure allows for local changes, comparison with original data,
    // and efficient updates without directly mutating the state.
    const { soapboxData, setTrackInstallAndVisibility } = useAuthContext(); 
    const [data, setData] = useState<Content | null>(null);
    const [originalData, setOriginalData] = useState<Content | null>(null);
    const [changesMadeLocally, setChangesMadeLocally] = useState<boolean>(false);
    const [changesInPendingCommit, setChangesInPendingCommit] = useState<boolean>(false);

    const theme: any = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (soapboxData) {
            const initializedData = initializeData(soapboxData);
            setData(initializedData);
            setOriginalData(initializedData);
            // console.log('Initialized data:', initializedData);
            setChangesInPendingCommit(false);
            // console.log("soapbox data updated")
        }
    }, [soapboxData]);

    useEffect(() => {
        // console.log("unsaved changes ", changesInPendingCommit)
    }, [changesInPendingCommit])

    const handleCheckboxChange = (artistId: string, trackId: string, field: keyof Track) => {
        if (!data) return;
        const newData = deepCopy(data);
        const artist = newData.artists.find((artist) => artist.id === artistId);
        if (artist) {
            const track = artist.tracks.find((track) => track.id === trackId);
            if (track) {
                (track[field] as boolean) = !(track[field] as boolean);
                if (field === 'install' && !track.install) {
                    track.visibility = false;
                }
                setData(newData);
                setChangesMadeLocally(true);
            }
        }
    };

    const handleArtistCheckboxChange = (artistId: string, checked: boolean, field: keyof Track) => {
        if (!data) return;
        const newData = deepCopy(data);
        const artist = newData.artists.find((artist) => artist.id === artistId);
        if (artist) {
            artist.tracks.forEach((track) => {
                (track[field] as boolean) = checked;
                if (field === 'install' && !checked) {
                    track.visibility = false;
                }
            });
            setData(newData);
            setChangesMadeLocally(true);
        }
    };

    const handleRevert = () => {
        if (originalData) {
            setData(deepCopy(originalData));
            setChangesMadeLocally(false);
        }
    };

    const handleSave = () => {
        if (data) {
            const strippedData = stripData(data);
            setOriginalData(deepCopy(data));
            setChangesMadeLocally(false);
            setChangesInPendingCommit(true);
            saveContent(strippedData);
        }
    };

    const stripData = (data: Content): ReturnData => {
        return {
            artists: data.artists.map((artist) => ({
                id: artist.id,
                tracks: artist.tracks.map((track) => ({
                    id: track.id,
                    visibility: track.visibility,
                    install: track.install,
                })),
            })),
        };
    };

    const saveContent = (data: ReturnData) => {
        setTrackInstallAndVisibility(data);
    };

    const getArtistCheckboxState = (artist: Artist) => {
        const allDownloaded = artist.tracks.every((track) => track.install);
        const noneDownloaded = artist.tracks.every((track) => !track.install);
        if (allDownloaded) return true;
        if (noneDownloaded) return false;
        return 'indeterminate';
    };

    const getArtistVisibilityCheckboxState = (artist: Artist) => {
        const allVisible = artist.tracks.every((track) => track.visibility);
        const noneVisible = artist.tracks.every((track) => !track.visibility);
        if (allVisible) return true;
        if (noneVisible) return false;
        return 'indeterminate';
    };

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ width: 'auto' }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!changesMadeLocally || changesInPendingCommit}
                    onClick={handleSave}
                >
                    Save my changes
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={!changesMadeLocally || changesInPendingCommit}
                    onClick={handleRevert}
                >
                    Revert unsaved changes
                </Button>
            </Stack>
            <Fade in={changesInPendingCommit} unmountOnExit>
                <LinearProgress color="primary" sx={{ mt: 1, mb: 1, height: '5px', width: '100%' }} />
            </Fade>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                {data.artists.filter((artist) => artist.tracks.length > 0).map((artist) => (
                    <Accordion key={artist.id} defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${artist.id}-content`}
                            id={`panel-${artist.id}-header`}
                        >
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Typography variant="h5">{artist.name}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: isMobile ? 1 : 0 }}>
                                    <Checkbox
                                        checked={getArtistCheckboxState(artist) === true}
                                        indeterminate={getArtistCheckboxState(artist) === 'indeterminate'}
                                        onChange={(e) => handleArtistCheckboxChange(artist.id, e.target.checked, 'install')}
                                        onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                                    />
                                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>Download {artist.tracks.filter((track) => track.install).length}/{artist.tracks.length}</Typography>
                                    <Checkbox
                                        checked={getArtistVisibilityCheckboxState(artist) === true}
                                        indeterminate={getArtistVisibilityCheckboxState(artist) === 'indeterminate'}
                                        onChange={(e) => handleArtistCheckboxChange(artist.id, e.target.checked, 'visibility')}
                                        onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                                        disabled={artist.tracks.some((track) => !track.install)}
                                    />
                                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>Visible {artist.tracks.filter((track) => track.visibility).length}/{artist.tracks.length}</Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow key="header-row">
                                        <TableCell key="header-track-name">Track Name</TableCell>
                                        <TableCell key="header-install" sx={{ width: '150px' }}>Download</TableCell>
                                        <TableCell key="header-visibility" sx={{ width: '150px' }}>Visible</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {artist.tracks.map((track) => (
                                        <TableRow key={`track-${track.id}`}>
                                            <TableCell>{track.title}</TableCell>
                                            <TableCell sx={{ width: '100px' }}>
                                                <Checkbox
                                                    checked={track.install}
                                                    onChange={() =>
                                                        handleCheckboxChange(artist.id, track.id, 'install')
                                                    }
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: '100px' }}>
                                                <Checkbox
                                                    checked={track.visibility}
                                                    onChange={() =>
                                                        handleCheckboxChange(artist.id, track.id, 'visibility')
                                                    }
                                                    color="primary"
                                                    disabled={!track.install}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </TableContainer>
        </Container >
    );
};

export default TrackTable;