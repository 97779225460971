import { Amplify } from 'aws-amplify';

if (process.env.REACT_APP_ENV_STAGE == "eduprod") {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: '6j72hu2b8gih9qmbl37uh9g39h',
                userPoolId: 'us-east-1_HQSCY0v1Z',
                loginWith: {
                    username: true
                }
            }
        }
    });
}
else if (process.env.REACT_APP_ENV_STAGE == "edustaging") {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: '2almjichj4i5m1l83bbf2kbr5l',
                userPoolId: 'us-east-1_XyENUUZFN',
                loginWith: {
                    username: true
                }
            }
        }
    });
}
else {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: '2v85if3llpbiqq5i6ijc8bafjv',
                userPoolId: 'us-east-1_T8wSnaCs3',
                loginWith: {
                    username: true
                }
            }
        }
    });
}
