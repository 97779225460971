import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';
import {
    Container,
    Box,
    IconButton,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Button,
    Chip,
    useMediaQuery
} from '@mui/material';
import { Refresh, ExpandLess, ExpandMore, Circle } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { DevicesResponse, DeviceArtist, Artist, Track, DeviceTrack, DeviceState } from '../models/TrackInstallVisibility';
import { time } from 'console';

const DeviceStatus = () => {
    const { institution, soapboxData, GetContent, utcTimeEpoch } = useAuthContext();
    const pin = institution.pin;
    const server_current_time_epoch: number = utcTimeEpoch;
    const devicesData: DevicesResponse = institution.devices;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date(server_current_time_epoch));
    const [nbTracksToInstall, setNbTracksToInstall] = useState<number>(0);

    // ignore warning
    const [currentTime, setCurrentTime] = useState<Date>(new Date()); // eslint-disable-line

    const updateSoapboxContent = async () => {
        // Fetch the content from the API
        console.log('Fetching content...');
        await GetContent();

    };

    // Memoize the function to avoid unnecessary re-renders
    const countNbTracksToInstall = useCallback(() => {
        let count = 0;
        if (soapboxData && soapboxData.artists) {
            soapboxData.artists.forEach((artist: Artist) => {
                artist.tracks.forEach((track: Track) => {
                    if (track.install) {
                        count++;
                    }
                });
            });
        }
        setNbTracksToInstall(count);

    }, [soapboxData]);

    useEffect(() => {
        setLastRefreshed(new Date(server_current_time_epoch));
    }, [server_current_time_epoch]);

    // Update the devicesData with the latest soapboxData
    // This will merge the tracks from soapboxData to devicesData, including the install, names
    // we then derive the status of each track depending on the install and installed values
    // we then derive the status of each device depending on the status of the tracks
    useEffect(() => {
        countNbTracksToInstall();
        devicesData.forEach(device => {
            device.artists.forEach(artist => {
                const soapboxArtist = soapboxData.artists.find((a: DeviceArtist) => a.id === artist.id);
                if (soapboxArtist) {
                    artist.name = soapboxArtist.name;
                    artist.tracks.forEach(track => {
                        const soapboxTrack = soapboxArtist.tracks.find((t: DeviceTrack) => t.id === track.id);
                        if (soapboxTrack) {
                            track.install = soapboxTrack.install;
                            track.name = soapboxTrack.title;
                        }
                    });
                }
            });

        }
        );

        // for each artist in soapboxData, add it and all the tracks with install = true to the devicesData
        // if the artist exists, still add the tracks that are not already in the deviceData
        devicesData.forEach(device => {
            soapboxData.artists.forEach((artist: Artist) => {
                const deviceArtist = device.artists.find((a: DeviceArtist) => a.id === artist.id);
                if (!deviceArtist) {
                    device.artists.push({
                        id: artist.id,
                        name: artist.name,
                        tracks: artist.tracks.filter(track => track.install).map(track => ({
                            id: track.id,
                            name: track.title,
                            install: track.install,
                            installed: false,
                            status: 'unknown'
                        }))
                    });
                }
                else {
                    artist.tracks.forEach(track => {
                        const deviceTrack = deviceArtist.tracks.find((t: DeviceTrack) => t.id === track.id);
                        if (!deviceTrack && track.install) {
                            deviceArtist.tracks.push({
                                id: track.id,
                                name: track.title,
                                install: track.install,
                                installed: false,
                                status: 'unknown'
                            });
                        }
                    });
                }

            });
        });

        // for all tracks of all devices in devicveData, set the status depending on the install and installed values
        devicesData.forEach(device => {
            device.artists.forEach(artist => {
                artist.tracks.forEach(track => {
                    if (track.install && track.installed) {
                        track.status = 'Ready';
                    }
                    else if (track.install && !track.installed) {
                        track.status = 'Download queued...';
                    }
                    else {
                        track.status = 'To be removed';
                    }


                });
            });
        });

        // set the devices status depending on the tracks status. if all tracks are ready, the device is ready
        devicesData.forEach(device => {
            // positively
            // const allTracksReady = device.artists.every(artist => artist.tracks.every(track => track.status === 'Ready'));
            // negatively
            const someTracksNotReady = device.artists.some(artist => artist.tracks.some(track => track.status !== 'Ready' && track.install));
            // device.status = allTracksReady ? 'Ready' : 'pending';
            device.status = someTracksNotReady ? DeviceState.PENDING : DeviceState.COMPLETE;
        });


    }, [soapboxData]); // eslint-disable-line

    // update last refreshed time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Function to handle the expand/collapse of the device details
    const handleExpandClick = (deviceId: string) => {
        setExpanded((prevState) => ({
            ...prevState,
            [deviceId]: !prevState[deviceId],
        }));
    };

    // Refs to store the interval and timeout for auto-refreshing the content
    const contentRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const contentRefreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Function to handle the refresh button click, which will clear and restart the interval and timeout for auto-refreshing the content
    const handleRefreshClick = async () => {
        // Clear existing interval and timeout
        if (contentRefreshIntervalRef.current) clearInterval(contentRefreshIntervalRef.current);
        if (contentRefreshTimeoutRef.current) clearTimeout(contentRefreshTimeoutRef.current);

        // Trigger the getContent method from the context
        await updateSoapboxContent();

        // Restart the interval and timeout
        contentRefreshIntervalRef.current = setInterval(async () => {
            await updateSoapboxContent();
        }, 60000); // 60000 ms = 1 minute

        contentRefreshTimeoutRef.current = setTimeout(() => {
            clearInterval(contentRefreshIntervalRef.current ?? undefined);
        }, 600000); // 600000 ms = 10 minutes
    };

    // Initial setup of the interval and timeout on component mount
    useEffect(() => {
        contentRefreshIntervalRef.current = setInterval(async () => {
            await updateSoapboxContent();
        }, 60000); // 60000 ms = 1 minute

        contentRefreshTimeoutRef.current = setTimeout(() => {
            clearInterval(contentRefreshIntervalRef.current ?? undefined);
        }, 600000); // 600000 ms = 10 minutes

        // Cleanup function to clear the interval and timeout if the component unmounts
        return () => {
            if (contentRefreshIntervalRef.current) clearInterval(contentRefreshIntervalRef.current);
            if (contentRefreshTimeoutRef.current) clearTimeout(contentRefreshTimeoutRef.current);
        };
    }, []);


    const getDeviceStatus = (lastUpdated: number) => {
        const isOnline = (server_current_time_epoch - lastUpdated) <= 5 * 60 * 1000; // 5 minutes
        return isOnline ? "online" : "offline";
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
                <IconButton size="small" onClick={handleRefreshClick} sx={{ marginRight: 1 }}>
                    <Refresh fontSize="small" />
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    Last refreshed {formatDistanceToNow(lastRefreshed, { addSuffix: true, includeSeconds: true })}
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Device</TableCell>
                            <TableCell>Provisioning status</TableCell>
                            <TableCell>Last seen</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devicesData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography variant="body2" color="textSecondary">
                                        No headsets configured yet.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (devicesData.map((device) => {
                            const lastUpdated = parseInt(device.last_updated_utc || '0');
                            const deviceConnectStatus = getDeviceStatus(lastUpdated);
                            const installedTracks = device.artists.reduce((count, artist) => {
                                return count + artist.tracks.filter(track => track.installed && track.install).length;
                            }, 0);
                            const nbTracksReady = device.artists.reduce((count, artist) => {
                                return count + artist.tracks.filter(track => track.status === 'Ready' && track.install).length;
                            }, 0);

                            const deviceStatusColor = device.status === DeviceState.COMPLETE ? 'success' : 'warning';

                            return (
                                <React.Fragment key={device.id}>
                                    <TableRow onClick={() => handleExpandClick(device.id)} >
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Typography
                                                    sx={{
                                                        fontWeight: 800
                                                    }}
                                                >
                                                    {device.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center" sx={isMobile ? { flexDirection: 'column' } : { flexDirection: 'row' }}>
                                                <Chip
                                                    label={device.status}
                                                    color={deviceStatusColor}
                                                    size={isMobile ? 'small' : 'medium'}
                                                    variant={nbTracksToInstall === 0 ? 'outlined' : 'filled'}
                                                    sx={{ marginRight: 1 }}
                                                />
                                                <Typography variant="body2" color="textSecondary">{device.status === DeviceState.COMPLETE ? `${installedTracks} tracks` : `${nbTracksReady}/${nbTracksToInstall} tracks`}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                {!isMobile &&
                                                    <Circle
                                                        sx={{
                                                            color: deviceConnectStatus === 'online' ? theme.palette.success.main : theme.palette.warning.main,
                                                            fontSize: '0.75rem',
                                                            marginRight: 0.5
                                                        }}
                                                    />}
                                                <Typography variant="body2" color="textSecondary">
                                                    {lastUpdated === 0 ? 'Never seen' :
                                                        formatDistanceToNow(lastUpdated) + ' ago'}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton >
                                                {expanded[device.id] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={expanded[device.id]} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <List dense>
                                                        {device.artists.map(artist =>
                                                            artist.tracks.filter(track => track.install).map(track => (
                                                                <ListItem key={track.id} sx={{ paddingY: 0.5 }}>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                                                                                    {artist.name} - {track.name}
                                                                                </Typography>
                                                                                <Chip
                                                                                    label={track.status === 'Ready' || deviceConnectStatus !== 'offline' ? track.status : 'Waiting for device availability...'}
                                                                                    variant='outlined'
                                                                                    color={track.status === 'Ready' ? 'success' : deviceConnectStatus === 'offline' ? 'warning' : 'info'}
                                                                                    size="small"
                                                                                    sx={{ marginLeft: 2 }}
                                                                                />
                                                                            </Box>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })
                        )}
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Typography
                                        variant={isMobile ? 'body2' : 'body1'}
                                        sx={{
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        Setup new headsets{isMobile && <br />} using this pin:
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ marginLeft: 2 }}
                                    >
                                        <Typography variant="h4">
                                            {pin}
                                        </Typography>
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
};

export default DeviceStatus;