import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Avatar, Box, useTheme, useMediaQuery, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import logoIcon from '../images/soapboxedu_icon_white.png';
import logoText from '../images/soapboxedu_white.png';
import { useAuthContext } from '../context/AuthContext';
import UserMenu from './UserMenu';

export default function AppHeader({ tab, setTab, tabs }: { tab: string, setTab: (tab: string) => void, tabs: string[] }) {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { username, handleSignOut } = useAuthContext();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Button color="inherit" onClick={() => setTab(tabs[0])}>
                    <img src={logoText} alt="Logo" style={{ height: isMobile ? 40 : 60 }} />
                </Button>
                {isMobile ? (
                    <>
                        <Box flexGrow={1} />
                        <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)} sx={{ marginRight: 0, height: 60, width: 60 }}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <Box
                                sx={{ width: 250 }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    <ListItem button onClick={() => setTab(tabs[0])}>
                                        <img src={logoText} alt="Logo" style={{ height: 60 }} />
                                    </ListItem>
                                    {tabs.map((tabName, index) => (
                                        <ListItem
                                            button
                                            key={index}
                                            selected={tabName === tab}
                                            onClick={() => setTab(tabName)}
                                        >
                                            <ListItemText primary={tabName} />
                                        </ListItem>
                                    ))}

                                    {/* spacing */}
                                    <ListItem>
                                        <ListItemText primary="" />
                                    </ListItem>

                                    <ListItem>
                                        <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" style={{ width: 60, height: 60 }} />
                                        <Box display="flex" alignItems="center" flexDirection="column" width="100%">
                                            <Typography variant="body1" color="inherit" style={{ marginTop: 4, fontSize: '0.75rem' }}>
                                                {username}
                                            </Typography>
                                            <Box component="form" width={'auto'} onSubmit={handleSignOut} noValidate sx={{ p: 0 }}>
                                                <Button type="submit" fullWidth variant="outlined" sx={{ mt: 1, mb: 1, height: '45px' }}>
                                                    Sign Out
                                                </Button>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" style={{ flexGrow: 1 }}>
                            {tabs.map((tabName, index) => (
                                <Button
                                    key={index}
                                    color={tabName === tab ? 'primary' : 'inherit'}
                                    onClick={() => setTab(tabName)}
                                    variant='text'
                                >
                                    {tabName}
                                </Button>
                            ))}
                        </Typography>
                        <UserMenu
                            username={username}
                            anchorEl={anchorEl}
                            handleMenu={handleMenu}
                            handleClose={handleClose}
                            handleSignOut={handleSignOut}
                        />
                    </>
                )}
            </Toolbar>
        </AppBar >
    );
}