import React, { createContext, useContext, ReactNode } from 'react';
import useAuth from '../hooks/useAuth';

import { Content } from '../models/content';

interface AuthContextType {
    signedIn: boolean;
    username: string;
    soapboxData: any;
    institution: any;
    handleSignIn: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    handleSignOut: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    signingIn: boolean;
    signInAllowed: boolean;
    errorValue: string | null;
    setTrackInstallAndVisibility: (content: any) => void;
    idToken: string;
    GetContent: () => Promise<void>;
    utcTimeEpoch: number;
    showTermsAndConditions: boolean;
    handleAcceptTerms: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const auth = useAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};