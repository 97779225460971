import React from 'react';
import { Box, Button, Typography, Paper, Link } from '@mui/material';

interface TermsAndConditionsProps {
  onAccept: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onAccept }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 3,
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome. Before proceeding, please review and accept our latest terms and conditions.
        </Typography>
        <Typography variant="body2" paragraph>
          By clicking "Accept", you acknowledge that you have read, understood, and agree to be bound by our{' '}
          <Link href="https://www.soapbox.us/terms-of-service#v110" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href="https://www.soapbox.us/privacy-policy#v110" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          .
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onAccept}
          sx={{ marginTop: 2 }}
        >
          Accept
        </Button>
      </Paper>
    </Box>
  );
};

export default TermsAndConditions;
