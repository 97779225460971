import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SignOutForm from './SignOutForm';

export default function SupportTab() {
    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h1">Support</Typography>
            {/* <Typography variant="body1" sx={{ mt: 2 }}>
                Phone: (123) 456-7890
            </Typography> */}
            {/* <Typography variant="body1" sx={{ mt: 2 }}>
                Opening Hours: Mon-Fri 9:00 AM - 5:00 PM CST
            </Typography> */}
            <Typography variant="body1" sx={{ mt: 2 }}>
                For any questions or concerns, please contact us at the email address below.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
                Email:
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    href="mailto:support@soapbox.us"
                    sx={{ ml: 1 }}
                >
                    support@soapbox.us
                </Button>
            </Typography>
        </Container>
    );
}