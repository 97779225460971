import React from 'react';
import { Box, CircularProgress, Table, Typography } from '@mui/material';

const MyComponent = () => {
    // Component logic goes here

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>Loading, please wait...</Typography>
            <Table sx={{ mt: 2, width: '80%' }}>
            </Table>
        </Box>
    );
};

export default MyComponent;