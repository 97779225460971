import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer = () => {

    return (
        <Box component="footer" sx={{
            py: 3, px: 2, mt: 'auto'
        }}>
            < Container maxWidth="sm" >
                <Typography variant="body1">
                    {/* <Link href="/terms" color="inherit">Terms and Conditions</Link> |
                    <Link href="/privacy" color="inherit"> Privacy Policy</Link> |
                    <Link href="/contact" color="inherit"> Contact Us</Link> */}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'All rights reserved Soapbox LLC © '}
                    {new Date().getFullYear()}
                </Typography>
                <Link href="mailto:support@soapbox.us" color="textSecondary" underline="hover" sx={{ display: 'block', mt: 1 }}>
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                        Contact support
                    </Typography>
                </Link>
            </Container >
        </Box >
    );
};

export default Footer;