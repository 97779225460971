import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import DeviceStatus from './DeviceStatus';
import FrequentlyAskedQuestion from './FrequentlyAskedQuestion';
import { List, ListItem, ListItemText, Link, Container, Typography, Box } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import TrackList from './TrackList';


const devicesFAQ = [
    {
        question: 'How to setup a headset?',
        answer:
            <>
                <Typography variant="body1">
                    To setup your headset and start provisioning content.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Step 1" secondary="Make sure your headset is charged and turned on" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 2" secondary={
                            <>
                                <Link href="https://www.meta.com/help/quest/articles/getting-started/getting-started-with-quest-2/connect-quest-to-wi-fi/">Connect your headset to the internet.</Link>
                            </>
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 3" secondary="Open the Soapbox app" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 4" secondary="Login using your institution's pin in the app, and keep the headset turned on" />
                    </ListItem>
                </List>
                <Typography variant="body1">
                    After following these steps, your headset will be available for content provisioning.
                </Typography>
            </>
    },
    {
        question: 'How to make my headset available?',
        answer:
            <>
                <Typography variant="body1">
                    Headset are considered available when recently connected to the internet, meaning that content can be provisioned.
                    To make your headset available, you need to follow the steps below:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Step 1" secondary="Make sure your headset is charged and turned on" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 2" secondary={
                            <>
                                <Link href="https://www.meta.com/help/quest/articles/getting-started/getting-started-with-quest-2/connect-quest-to-wi-fi/">Connect your headset to the internet</Link>
                            </>
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 3" secondary="Open the Soapbox app" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Step 4" secondary="Login using your institution's pin in the app, and keep the headset turned on" />
                    </ListItem>
                </List>
                <Typography variant="body1">
                    After following these steps, your headset will be available for content provisioning.
                </Typography>
            </>
    },
    {
        question: 'Hardware support',
        answer:
            <>
                <Typography variant="body1">
                    Please reach out to our support team for any hardware-related issues from the support tab.
                </Typography>
            </>
    }
];

const tracksFAQ = [
    {
        question: 'What\'s the difference between Download and Visible?',
        answer:
            <>
                <List>
                    <ListItem>
                        <ListItemText primary="Download" secondary="Download the content to all your headsets as soon as they get online. This process takes time and we recommend using a fast internet connection. Please make sure your content is sucessfully provisioned to each headset after making changes." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Visible" secondary="This option allows you to hide and show the content on the app without having to remove it from the headset. This setting will take effect on each headset on app restart." />
                    </ListItem>
                </List>
            </>
    }
];


export default function DashboardTab() {

    const { soapboxData } = useAuthContext();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (soapboxData !== null) {
            setLoading(false);
        }
    }, [soapboxData]);

    return (
        loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>Loading, please wait...</Typography>
                <Table sx={{ mt: 2, width: '80%' }}>
                </Table>
            </Box>
        ) : (
            <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>

                <Box sx={{ mb: 4 }}>
                    <Typography variant="h2">Headsets</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Manage your soapbox headsets and track content provisioning in real time.
                    </Typography>
                    <DeviceStatus />
                    <Box sx={{ padding: 2 }}>
                        <FrequentlyAskedQuestion frequentlyAskedQuestions={devicesFAQ} />
                    </Box>
                </Box>

                <Box sx={{ mb: 4 }}>
                    <Typography variant="h2">Content provisioning</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Manage which content gets provisioned and visible on the Soapbox app for your users.
                    </Typography>
                    <TrackList />
                    <Box sx={{ padding: 2 }}>
                        <FrequentlyAskedQuestion frequentlyAskedQuestions={tracksFAQ} />
                    </Box>
                </Box>


            </Container>
        )
    );
}