import React from 'react';
import { Container } from '@mui/material';
import SignInForm from './SignInForm';

import logo from '../images/soapboxedu_white.png';
import Typography from '@mui/material/Typography';

import Footer from './Footer';

export default function LoginPage() {


    return (
        <Container component="main" maxWidth="xs">
            {/* logo size depends on isMobile */}
            <img src={logo} className="logo" alt="logo" style={{ width: '100%', height: 'auto' }} />
            <Typography variant="h6" align="center" gutterBottom sx={{ mt: 3, mb: 2 }}>
                Admin Portal
            </Typography>
            <SignInForm />
            <Footer />
        </Container>
    );
}